"use client";

import * as Primitive from "@radix-ui/react-select";
import { CSS, makeComponent, t, tw, v } from "./tailwind";
import { CheckIcon } from "@reframe.so/icons/icons/CheckIcon";
import { ChevronDownIcon } from "@reframe.so/icons/icons/ChevronDownIcon";
import React from "react";

const Root = Primitive.Root;

const Group = tw(Primitive.Group, t``);

const Value = tw(Primitive.Value, t``);

const Portal = Primitive.Portal;

const ItemText = tw(
  Primitive.ItemText,
  t`inline-flex grow w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-primary-50 data-[disabled]:pointer-events-none data-[disabled]:opacity-50`
);

const Viewport = tw(Primitive.Viewport, t`p-1`);

const Content = tw(
  Primitive.Content,
  t`relative z-50 min-w-[8rem] overflow-hidden rounded-md border shadow-md animate-in fade-in-80 translate-y-1 bg-white`
);

const Trigger = makeComponent({
  as: Primitive.Trigger,
  from: v({
    base: [
      t`flex h-10 w-full items-center justify-between rounded-md border border-neutral-400/50 bg-transparent px-3 py-2 text-sm ring-offset-primary-50  placeholder:text-neutral-400 focus:outline-none focus:ring-2 focus:ring-primary-300 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50`,
      t`text-left [&>span]:line-clamp-1`,
    ],
  }),
  name: "Select.Trigger",
  useOverride(props) {
    return {
      children: (
        <>
          {props.children}
          <Primitive.Icon asChild>
            <ChevronDownIcon className="h-4 w-4 shrink-0 stroke-2 opacity-50" />
          </Primitive.Icon>
        </>
      ),
    };
  },
});

const OptionIndicator = tw(
  Primitive.ItemIndicator,
  t`option-indicator absolute left-2 inline-flex h-3.5 w-3.5 items-center justify-center`
);

export const Option = makeComponent({
  as: Primitive.Item,
  from: v({
    base: t`relative flex w-full cursor-pointer select-none items-center rounded-sm py-1.5 px-2 pl-8 gap-2 text-sm outline-none focus:bg-primary-50 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 hover:bg-neutral-200`,
    variants: {},
  }),
  name: "Select.Option",
  useOverride(props) {
    return {
      children: (
        <>
          <OptionIndicator>
            <CheckIcon css="h-4 w-4 stroke-2" />
          </OptionIndicator>

          <ItemText>{props.children}</ItemText>
        </>
      ),
    };
  },
});

export const Select = Object.assign(
  ({
    children,
    placeholder,
    css,
    value,
    onChange,
    position = "popper",
    icon,
    renderValue,
  }: {
    children: React.ReactNode;
    placeholder?: React.ReactNode;
    css?: {
      trigger?: CSS;
      content?: CSS;
    };
    value?: string;
    onChange?: (_: string) => void;
    position?: "item-aligned" | "popper";
    icon?: boolean;

    renderValue?: (value?: string) => React.ReactNode;
  }) => {
    return (
      <Root value={value} onValueChange={(e) => onChange?.(e)}>
        <Trigger css={css?.trigger}>
          {renderValue ? (
            renderValue(value)
          ) : (
            <Value placeholder={placeholder} />
          )}
        </Trigger>
        <Portal>
          <Content css={css?.content} position={position}>
            <Viewport
              css={[
                position === "item-aligned"
                  ? ""
                  : "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]",
                icon === false
                  ? "[&_[data-rf=select-option-class=icon]:hidden"
                  : "",
              ]}
            >
              <Group>{children}</Group>
            </Viewport>
          </Content>
        </Portal>
      </Root>
    );
  },
  {
    Option,
  }
);

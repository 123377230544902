"use client";

import * as Primitive from "@radix-ui/react-menubar";
import { CSS, t, tw } from "./tailwind";
import React from "react";

const Menu = tw(Primitive.Menu, t`p-0`);

const Root = tw(Primitive.Root, t`flex items-center p-0 bg-transparent`);

const Trigger = tw(
  Primitive.Trigger,
  t` flex cursor-default select-none font-medium outline-none`
);

const Portal = Primitive.Portal;

const Content = tw(
  Primitive.Content,
  t` z-50 min-w-[12rem] overflow-hidden rounded-md border p-1 shadow-md animate-in slide-in-from-top-1 bg-white`
);

const Item = tw(
  Primitive.Item,
  t`relative flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-primary-50 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 hover:bg-neutral-100 `
);

const offset = {
  center: {
    align: 0,
    side: 0,
  },

  start: {
    align: 0,
    side: 4,
  },

  end: {
    align: 0,
    side: 4,
  },
} as const;

export const Menubar = Object.assign(
  ({
    trigger,
    position = "end",
    children,
    css,
  }: {
    trigger: React.ReactElement;
    position?: keyof typeof offset;
    children: React.ReactNode;
    css?: CSS;
  }) => {
    return (
      <Root>
        <Menu>
          <Trigger>{trigger}</Trigger>
          <Portal>
            <Content
              align={position}
              alignOffset={offset[position].align}
              sideOffset={offset[position].side}
              css={css}
            >
              {children}
            </Content>
          </Portal>
        </Menu>
      </Root>
    );
  },
  { Item }
);

"use client";

import * as Primitive from "@radix-ui/react-toast";
import { makeComponent, t, tw, v } from "./tailwind";
import { useEffect, useState } from "react";
import { XIcon } from "@reframe.so/icons/icons/XIcon";

const Provider = tw(Primitive.Provider, t``);

const Viewport = tw(
  Primitive.Viewport,
  t`fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 md:right-0 md:flex-col md:max-w-[420px]`
);

const toast = v({
  base: t`data-[swipe=move]:transition-none group relative pointer-events-auto flex w-full items-center justify-between space-x-4 overflow-hidden rounded-md border p-6 pr-8 shadow-lg transition-all data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full data-[state=closed]:slide-out-to-right-full`,
  variants: {
    variant: {
      default: t`bg-neutral-50 border`,
      error: t`
      group error bg-rose-400 text-rose-100 child-[]/action:border-rose-500/30 child-[]/action:hover:border-rose-500/30 child-[]/action:hover:bg-rose-500 child-[]/action:hover:text-rose-700 child-[]/action:focus:ring-rose-400`,
      success: t`group success bg-emerald-400 text-neutral-600 child-[]/action:border-emerald-500/30 child-[]/action:hover:border-emerald-500/30 child-[]/action:hover:bg-emerald-500 child-[]/action:hover:text-emerald-700 child-[]/action:focus:ring-emerald-400 child-[]/action:scale`,
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

const Root = makeComponent({
  as: Primitive.Root,
  from: toast,
  name: "Toast",
});

const Action = tw(
  Primitive.Action,
  t`child/action inline-flex h-8 shrink-0 items-center justify-center rounded-md border bg-transparent px-3 text-sm font-medium ring-offset-neutral-50 transition-colors hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-300 focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50`
);

const Close = tw(
  Primitive.Close,
  t`absolute right-2 top-2 rounded-md p-1 text-neutral-950 transition-opacity focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100 group-[.red]:text-red-300 group-[.red]:hover:text-red-50 group-[.red]:focus:ring-red-400 group-[.red]:focus:ring-offset-red-600`
);

const Title = tw(Primitive.Title, t`text-sm font-semibold`);

const Description = tw(Primitive.Description, t`text-sm opacity-90`);

const TOAST_LIMIT = 3;
const TOAST_REMOVE_DELAY = 100000;

type ToastProps = React.ComponentPropsWithoutRef<typeof Root>;
type ActionProps = React.ReactElement<typeof Action>;

type ToasterToast = ToastProps & {
  id: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  action?: ActionProps;
};

const actionTypes = {
  ADD_TOAST: "ADD_TOAST",
  UPDATE_TOAST: "UPDATE_TOAST",
  DISMISS_TOAST: "DISMISS_TOAST",
  REMOVE_TOAST: "REMOVE_TOAST",
} as const;

let count = 0;

function genId() {
  count = (count + 1) % Number.MAX_VALUE;

  return count.toString();
}

type ActionType = typeof actionTypes;

type ActionPayload =
  | {
      type: ActionType["ADD_TOAST"];
      toast: ToasterToast;
    }
  | {
      type: ActionType["UPDATE_TOAST"];
      toast: Partial<ToasterToast>;
    }
  | {
      type: ActionType["DISMISS_TOAST"];
      toastId?: ToasterToast["id"];
    }
  | {
      type: ActionType["REMOVE_TOAST"];
      toastId?: ToasterToast["id"];
    };

interface State {
  toasts: ToasterToast[];
}

const toastTimeouts = new Map<string, ReturnType<typeof setTimeout>>();

const addToRemoveQueue = (toastId: string) => {
  if (toastTimeouts.has(toastId)) {
    return;
  }

  // console.log("add to remove queue", toastId);
  const timeout = setTimeout(() => {
    toastTimeouts.delete(toastId);
    dispatch({
      type: "REMOVE_TOAST",
      toastId,
    });
    // console.log("remove toast", toastId);
  }, TOAST_REMOVE_DELAY);

  toastTimeouts.set(toastId, timeout);
};

export const reducer = (state: State, action: ActionPayload): State => {
  switch (action.type) {
    case "ADD_TOAST":
      return {
        ...state,
        toasts: [action.toast, ...state.toasts].slice(0, TOAST_LIMIT),
      };

    case "UPDATE_TOAST":
      return {
        ...state,
        toasts: state.toasts.map((t) =>
          t.id === action.toast.id ? { ...t, ...action.toast } : t
        ),
      };

    case "DISMISS_TOAST": {
      const { toastId } = action;

      // ! Side effects ! - This could be extracted into a dismissToast() action,
      // but I'll keep it here for simplicity
      if (toastId) {
        addToRemoveQueue(toastId);
      } else {
        state.toasts.forEach((toast) => {
          addToRemoveQueue(toast.id);
        });
      }

      return {
        ...state,
        toasts: state.toasts.map((t) =>
          t.id === toastId || toastId === undefined
            ? {
                ...t,
                open: false,
              }
            : t
        ),
      };
    }
    case "REMOVE_TOAST":
      if (action.toastId === undefined) {
        return {
          ...state,
          toasts: [],
        };
      }

      return {
        ...state,
        toasts: state.toasts.filter((t) => t.id !== action.toastId),
      };
  }
};

const listeners: Array<(state: State) => void> = [];

let memoryState: State = { toasts: [] };

function dispatch(action: ActionPayload) {
  // console.log("dispatch", action);
  memoryState = reducer(memoryState, action);
  listeners.forEach((listener) => {
    listener(memoryState);
  });
}

interface CreateToastProps extends Omit<ToasterToast, "id"> {}

function createToast(props: CreateToastProps) {
  const id = genId();

  const update = (props: ToasterToast) =>
    dispatch({
      type: "UPDATE_TOAST",
      toast: { ...props, id },
    });

  const dismiss = () => dispatch({ type: "DISMISS_TOAST", toastId: id });

  dispatch({
    type: "ADD_TOAST",
    toast: {
      ...props,
      id,
      open: true,
      onOpenChange: (open) => {
        if (!open) {
          dismiss();
        }
      },
    },
  });

  return {
    id,
    dismiss,
    update,
  };
}

function useToast() {
  const [state, setState] = useState<State>(memoryState);

  useEffect(() => {
    listeners.push(setState);

    return () => {
      const index = listeners.indexOf(setState);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    };
  }, [state]);

  return {
    ...state,
    toast: createToast,
    dismiss: (toastId?: string) => dispatch({ type: "DISMISS_TOAST", toastId }),
  };
}

function ToastProvider() {
  const { toasts } = useToast();

  return (
    <Provider>
      {toasts.map(({ id, title, description, action, ...props }) => {
        return (
          <Root key={id} {...props}>
            <div className="grid gap-1">
              {title && <Title>{title}</Title>}
              {description && <Description>{description}</Description>}
            </div>
            {action}
            <Close toast-close="">
              <XIcon css="h-4 w-4" />{" "}
            </Close>
          </Root>
        );
      })}
      <Viewport />
    </Provider>
  );
}

export const Toast = {
  Provider: ToastProvider,
  create: createToast,
  Action,
};

"use client";

import React, { useEffect, useImperativeHandle, useRef } from "react";

import { MakeProps, makeComponent, t, v } from "./tailwind";

export const input = v({
  base: t`placeholder:text-neutral-400 flex h-10 w-full rounded-md bg-transparent px-3 py-2 text-sm file:bg-transparent file:text-sm file:font-medium disabled:cursor-not-allowed disabled:opacity-50 text-neutral-700`,
  variants: {
    bordered: {
      false: t`border-0`,
      true: t`border border-neutral-400/50 file:border-0`,
    },
    outline: {
      false: t`ring-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0`,
      true: t`ring-offset-primary-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-300 focus-visible:ring-offset-2`,
    },
  },
  defaultVariants: {
    outline: true,
    bordered: true
  },
});

export type InputProps = MakeProps<typeof input, "input">;

const Input = makeComponent({
  from: input,
  as: "input",
  name: "Input",
  useOverride: (props, ref) => {
    const cursor = useRef<number | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useImperativeHandle(ref, () => inputRef.current!);

    useEffect(() => {
      if (
        inputRef.current &&
        cursor.current !== null &&
        inputRef.current.type !== "email"
      ) {
        inputRef.current.setSelectionRange(cursor.current, cursor.current);
      }
    }, [inputRef, props.value]);

    return {
      ref: inputRef,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        cursor.current = e.target.selectionStart;

        return props.onChange?.(e);
      },
    };
  },
});

export { Input };
